import React from 'react';
import whirlpoolLogo from '../assets/images/whirlpool.svg';
import hotpointLogo from '../assets/images/hotpoint.svg';
import baucknechtLogo from '../assets/images/baucknecht.svg';
import indesitLogo from '../assets/images/indesit.svg';
import privilegLogo from '../assets/images/privileg.svg';
import ignislLogo from '../assets/images/ignis.jpg';
import kitchenaidLogo from '../assets/images/kitchenaid.svg';
import BrandMapper from "../services/BrandMapper"
import LanguageRetriever from "../services/LanguageRetriever"

const country = LanguageRetriever(['COUNTRY'])

export default function Nav({ brand }) {
  return (
    <div className="container container--nav">
      <div className="nav__items">
        {BrandMapper(brand) === 'ka'
          ?
          <div className="nav__item item-ka-logo"><img src={kitchenaidLogo} alt="Kitchenaid" /></div>
          :
          <>
            <div className="nav__item item-wpl-logo"><img src={whirlpoolLogo} alt="Whirlpool" /></div>
            <div className="nav__item"><img src={hotpointLogo} alt="Hotpoint" /></div>
            {showBaucknechtLogo() && <div className="nav__item"><img src={baucknechtLogo} alt="Baucknecht" /></div>}
            <div className="nav__item"><img src={indesitLogo} alt="Indesit" /></div>
            {showPrivilegLogo() && <div className="nav__item"><img src={privilegLogo} alt="Privileg" /></div>}
            {showIgnislLogo() && <div className="nav__item ignis"><img src={ignislLogo} alt="Ignis" /></div>}
          </>
        }
      </div>
    </div>
  );
}

function showBaucknechtLogo () {
  if (country === 'uk' || country === 'ie' || country === 'gb') {
    return false
  }
  return true
}

function showPrivilegLogo () {
  if (country === 'uk' || country === 'ie' || country === 'gb') {
    return false
  }
  return true
}

function showIgnislLogo () {
  if (country === 'uk' || country === 'ie' || country === 'gb') {
    return false
  }
  return true
}
