import QueryString from './QueryString';

const IndustrialCodeExtractor = function (scannedCode) {
  const NOT_VALID_DEFAULT_VALUE = false

  if (scannedCode.startsWith('https')) {
    const queryString = QueryString(scannedCode)
    return queryString.indc || NOT_VALID_DEFAULT_VALUE;
  }

  switch (scannedCode.length) {
    case 24:
      let industrialCode = scannedCode.substr(0, 12)
      return (industrialCode.charAt(0) === '9' || industrialCode.charAt(0) === 'W') ? industrialCode.substr(1, 11) : industrialCode
    case 20:
      return scannedCode.startsWith('999') ? "75".concat(scannedCode).substr(0,12) : scannedCode.substr(0, 11)
    case 12:
      return scannedCode.startsWith('85') ? scannedCode :  NOT_VALID_DEFAULT_VALUE
    default:
      return NOT_VALID_DEFAULT_VALUE
  }
}

export default IndustrialCodeExtractor
