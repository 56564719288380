import React, { useState, useEffect } from 'react';
import {useTranslation} from "react-i18next";
import washersImage from '../assets/images/washers.png'
import washerdryersImage from '../assets/images/washerdryers.png'
import dryersImage from '../assets/images/dryers.png'
import cookingImage from '../assets/images/cookers.png'
import ovensImage from '../assets/images/ovens.png'
import fridgefreezerImage from '../assets/images/fridges.png'
import freezerImage from '../assets/images/freezers.png'
import dishwasherImage from '../assets/images/dishwashings.png'
import idLabelImage from '../assets/images/id_label.png'
import qrCodeLabelImage from '../assets/images/qr_code.png'

export default function CategoryList(props) {
  const { t, i18n } = useTranslation('common');
  const [categorySelected, setCategorySelected] = useState(t('main.popup_select'))
  const [rowCategorySelected, setRowCategorySelected] = useState('')
  const [showCategoryList, setShowCategoryList] = useState(false)
  const [showImage, setShowImage] = useState(false)

  useEffect(() => {

  })

  function openCategoryList() {
    setShowCategoryList(!showCategoryList)
  }

  function chooseCategory(category) {
    setCategorySelected(t('main.categories.' + category))
    setRowCategorySelected(category)
    setShowCategoryList(false)
    setShowImage(true)
  }

  function isCategory(category) {
    if (category === rowCategorySelected) return true
  }

  function retrieveSortedCategoryList() {
    return [
      { 'name': 'washingmachine',  'label': t('main.categories.washingmachine'),  'image': washersImage },
      { 'name': 'washerdryer',     'label': t('main.categories.washerdryer'),     'image': washerdryersImage},
      { 'name': 'dryer',           'label': t('main.categories.dryer'),           'image': dryersImage},
      { 'name': 'cooking',         'label': t('main.categories.cooking'),         'image': cookingImage},
      { 'name': 'oven',            'label': t('main.categories.oven'),            'image': ovensImage},
      { 'name': 'fridgefreezer',   'label': t('main.categories.fridgefreezer'),   'image': fridgefreezerImage},
      { 'name': 'freezer',         'label': t('main.categories.freezer'),         'image': freezerImage},
      { 'name': 'dishwasher',      'label': t('main.categories.dishwasher'),      'image': dishwasherImage},
    ].sort((a, b) => a['label'].localeCompare(b['label']))
  }

  return (
    <div className="code-selection">
      <div className="code-selection__container">
        <div className="container-category-list">
          <div className="category__items">
            <div className="category__item first" onClick={() => { openCategoryList() }}>
              <div className="category">{categorySelected}</div>
            </div>
            {showCategoryList
              ?
              <div className="category__list">
                <ul>
                  {retrieveSortedCategoryList().map((category) => (
                    <li onClick={() => { chooseCategory(category['name']) }}>
                      {category['label']}
                    </li>
                  ))}
                </ul>
              </div>
              :
              <div></div>
            }
          </div>
        </div>
        {showImage
          ?
          <div className="code-selection__images">
            <div className="category-code-image">
              {retrieveSortedCategoryList().map((category) => (
                isCategory(category['name']) ? <img src={category['image']} alt={category['label']} title={category['label']} /> : <span></span>
              ))}
            </div>
            <div className="code-image">
              <img src={idLabelImage} />
            </div>
            <div className="code-image">
            <img src={qrCodeLabelImage} />
            </div>
          </div>
          :
          <div></div>
        }
      </div>
    </div>
  )
}