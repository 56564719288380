const DEFAULT_LANGUAGE = 'it'
const DEFAULT_LANGUAGE_PARAMETER = ['COUNTRY']
const AVAILABLE_LANGUAGES = [
  'it',
  'de',
  'nl',
  'fr',
  'es',
  'pt',
  'no',
  'da',
  'sv',
  'fi',
  'uk',
  'en',
  'ie',
  'gb',
  'pl',
  'hu',
  'sk',
  'cz',
  'cs'
]

/**
 * Retrieve the queryLanguageParameter from the URL and return it after a validation.
 * If the language is not valid, return the DEFAULT_LANGUAGE.
 * The list of valid languages can be found in the string[] AVAILABLE_LANGUAGES.
 * All the checks are made on lowercase values.
 * @param {string} queryLanguageParameter the query parameter name. Default value: DEFAULT_LANGUAGE_PARAMETER
 * @returns the queryLanguageParameter value. If the value is not valid, return the DEFAULT_LANGUAGE value
 */
const LanguageRetriever = function (queryLanguageParameter = DEFAULT_LANGUAGE_PARAMETER) {
  // retrieve parameter queryLanguageParameter from the URL
  let selectedLanguage
  for (let i = 0; i < queryLanguageParameter.length; i++) {
    selectedLanguage = _getLanguageFromQueryStringParameter(queryLanguageParameter[i])

    if (selectedLanguage != 'false') {
      break
    }
  }

  // return the found language after validation. DEFAULT_LANGUAGE is returned if no valid language can be found
  return (
    _validateLanguage(selectedLanguage, AVAILABLE_LANGUAGES) ?
      selectedLanguage :
      DEFAULT_LANGUAGE
  )
}

/**
 * Retrieve the queryLanguageParameter from the URL and return it after a validation.
 * @param {string} queryLanguageParameter the query parameter name. Default value: DEFAULT_LANGUAGE_PARAMETER
 * @returns the queryLanguageParameter value. If the value is not valid, return the DEFAULT_LANGUAGE value
 */
const _getLanguageFromQueryStringParameter = function (queryLanguageParameter) {
  // retrieve parameter queryLanguageParameter from the URL
  let result =
    (window.location.search.toLowerCase().match(
      new RegExp(
        '[?&]' + queryLanguageParameter.toLowerCase() + '=([^&]+)')
      )
      || [, 'false']
    )[1].toLowerCase()

  return result
}

/**
 * Check if the language string parameter is contained in the availableLanguages string[].
 * @param {string} language the language to check
 * @param {string[]} availableLanguages the list of the available languages
 * @returns true if the string language is contained in the string[] availableLanguages
 */
const _validateLanguage = function (language, availableLanguages) {
  return availableLanguages.includes(language)
}

export default LanguageRetriever
