import React from 'react';
import {withTranslation} from "react-i18next";

import Nav from './components/Nav';
import Hero from './components/Hero';
import MainAction from './components/MainAction';

import formatURL from './services/FormatURL';
import BrandMapper from "./services/BrandMapper"

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
      country: null,
      lang: null,
      brand: null,
      prod_family: null,
      source_system: null,
      isTokenValid: 0,
      isResponseLoaded: 0
    };
  }

  getQueryParameterValue(params, name){
    let result = ''
    params.get(name) ? result = params.get(name) : result = '---'
    if (result !== '---') return result
    return params.get(name.toUpperCase()) ?? '---'
  }

  componentDidMount() {
    const { t } = this.props;
    document.title = t('meta.title');
    document.getElementsByTagName("meta")[3].content=t('meta.description');

    const queryParams = new URLSearchParams(window.location.search);

    let parameters = []
    parameters['token'] = this.getQueryParameterValue(queryParams, 'ident');
    parameters['country'] = this.getQueryParameterValue(queryParams, 'country');
    parameters['lang'] = this.getQueryParameterValue(queryParams, 'lang');
    parameters['brand'] = this.getQueryParameterValue(queryParams, 'brand');
    parameters['prod_family'] = this.getQueryParameterValue(queryParams, 'prodfam');
    parameters['source_system'] = this.getQueryParameterValue(queryParams, 'system');

    this.setState({
      token: parameters['token'],
      country: parameters['country'],
      lang: parameters['lang'],
      brand: parameters['brand'],
      prod_family: parameters['prod_family'],
      source_system: parameters['source_system']
    })

    const url = formatURL(process.env.REACT_APP_API_HOST + process.env.REACT_APP_URL_VALIDATE, {
      token: parameters['token'],
      country: parameters['country'],
      source_system: parameters['source_system']
    })
  
    fetch(url)
      .then((response) => {
        response.ok ? this.setState({isTokenValid: 1}) : this.setState({isTokenValid: 0}) 
      })
      .then(() => {
        this.setState({isResponseLoaded: 1})
      })
      .catch((error) => {
        this.setState({isTokenValid: 0})
        this.setState({isResponseLoaded: 1})
      })
  }

  render() {
    return (
      <div className="app" data-brand={BrandMapper(this.state.brand)}>
        <Nav brand={this.state.brand}/>
        <Hero />
        <MainAction
          isResponseLoaded = {this.state.isResponseLoaded}
          valid={this.state.isTokenValid}
          token={this.state.token}
          country={this.state.country}
          lang={this.state.lang}
          source_system={this.state.source_system}
          brand={this.state.brand}
          prod_family={this.state.prod_family}
        />
      </div>
    );
  }
  
}

export default withTranslation('common')(App)
