import React from 'react';
import {useTranslation} from "react-i18next";


export default function Hero() {
  const { t, i18n } = useTranslation('common');

  return (
    <div className="container container--hero">
      <div className="hero">
        <div className="txt__title">{t('intro.title')}</div>
        <div className="txt__first_line">{t('intro.row-1')}</div>
        <div className="txt__second_line">{t('intro.row-2')}</div>
        <div className="txt__third_line">{t('intro.row-3')}</div>
      </div>
    </div>
  );
}
