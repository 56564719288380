import React from 'react';
import {useTranslation} from "react-i18next";


export default function DispalyResult(props) {
  const { t, i18n } = useTranslation('common');

  return (
    <div className="container container--form">
      <div className="form__items">
        {props.isScanResultValid
          ?
            <div className="form__item first" >
              {t('main.ok')}
            </div>
          :
            <div className="form__item first" >
              {t('main.retry')}
              <br/>
              <button onClick={props.processRestart}>{t('main.retry_action')}</button>
            </div>
        }
      </div>
    </div>
  );
}
