import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import common_it from "./translations/it/common.json";
import common_en from "./translations/en/common.json";
import common_de from "./translations/de/common.json";
import common_fr from "./translations/fr/common.json";
import common_nl from "./translations/nl/common.json";
import common_es from "./translations/es/common.json";
import common_pt from "./translations/pt/common.json";
import common_da from "./translations/da/common.json"; // Denmark: Country => DK | Language => DA
import common_sv from "./translations/sv/common.json"; // Sweden: Country => SE | Language => SV
import common_no from "./translations/no/common.json";
import common_fi from "./translations/fi/common.json";
import common_uk from "./translations/uk/common.json";
import common_ie from "./translations/ie/common.json";
import common_gb from "./translations/gb/common.json";
import common_pl from "./translations/pl/common.json";
import common_hu from "./translations/hu/common.json";
import common_sk from "./translations/sk/common.json";
import common_cz from "./translations/cz/common.json";
import LanguageRetriever from "./services/LanguageRetriever"

import App from './App';

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: LanguageRetriever(['LANG', 'COUNTRY']),      // language to use
  resources: {
    en: {
      common: common_en
    },
    it: {
      common: common_it
    },
    de: {
      common: common_de
    },
    fr: {
      common: common_fr
    },
    nl: {
      common: common_nl
    },
    es: {
      common: common_es
    },
    pt: {
      common: common_pt
    },
    sv: {
      common: common_sv
    },
    da: {
      common: common_da
    },
    no: {
      common: common_no
    },
    fi: {
      common: common_fi
    },
    uk: {
      common: common_uk
    },
    ie: {
      common: common_ie
    },
    gb: {
      common: common_gb
    },
    pl: {
      common: common_pl
    },
    hu: {
      common: common_hu
    },
    sk: {
      common: common_sk
    },
    cz: {
      common: common_cz
    },
    cs: {
      common: common_cz
    }
  },
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
        <App/>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
