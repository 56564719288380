import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import PopUp from './PopUp'
import BarcodeScanner from './BarcodeScanner';
import TokenValidationError from './errors/TokenValidationError';
import DispalyResult from './DisplayResult';
import BrandMapper from "../services/BrandMapper"

import formatURL from '../services/FormatURL';
import LanguageRetriever from "../services/LanguageRetriever"

export default function MainAction(props) {
  const { t, i18n } = useTranslation('common');
  const [scanner, setScanner] = useState(false)
  const [cameraPermission, setCameraPermission] = useState(true)
  const [scanResult, setScanResult] = useState(false)
  const [isScanResultValid, setIsScanResultValid] = useState(false)
  const [industrialCode, setIndustrialCode] = useState(false)
  const [serialNumber, setSerialNumber] = useState(false)
  const footerLinkUrl = footerLink ();

  function processRestart() {
    setScanner(false)
    setScanResult(false)
    setIndustrialCode(false)
  }

  function showScanner() {
    setScanner(true)
  }

  async function hideScanner() {
    setScanner(false)
    setCameraPermission(await cameraAvailable())
  }

  function getScanResult(industrialCode, serialNumber) {
    const url = formatURL(process.env.REACT_APP_API_HOST + process.env.REACT_APP_URL_SAVE)
    let data = {
      token: props.token,
      country: props.country,
      source_system: props.source_system,
      industrial_code: industrialCode,
      serial_number: serialNumber
    }
    //console.log(data);
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then((response) => {
        response.ok ? setIsScanResultValid(true) : setIsScanResultValid(false)
      })
      .then(() => {
        setScanResult(true)
      })
      .then(() => {
        setIndustrialCode(industrialCode)
      })
      .then(() => {
        setSerialNumber(serialNumber)
        console.log(serialNumber)
      })
      .catch((error) => {
        setIsScanResultValid(false)
        setScanResult(true)
        setIndustrialCode(industrialCode)
        setSerialNumber(serialNumber)
      })


  }

  async function cameraAvailable() {
    let stream = null;
    try {
      stream = await navigator.mediaDevices.getUserMedia({ audio: false, video: true });
      const tracks = stream.getTracks()
      tracks.forEach(track => track.stop())
      return true
    } catch (err) {
      return false
    }
  }

  return (
    <div>
      {!scanResult
        ?
        <div className="container container--form">
          {props.valid === 0
            ?
            <TokenValidationError isResponseLoaded={props.isResponseLoaded} />
            :
            <div className="form__items">
              <PopUp />
              <div className="form__item first" >
                <span>{t('main.pre_button')}</span>
                <br />
                {!scanner
                  ?
                  <div>
                    {cameraPermission
                      ?
                      <button onClick={showScanner}>{t('main.start')}</button>
                      :
                      <p className="error">{t('main.no_permissions')}</p>
                    }
                    <div className="form__item additional-information">
                      <span className="additional-information__title">
                        {t('main.scan_additional_information_title')}
                      </span>
                      &nbsp;
                      <span className="additional-information__text">
                        {t('main.scan_additional_information_text')}
                      </span>
                    </div>
                  </div>
                  :
                  <div>
                    <BarcodeScanner hideScanner={hideScanner} getScanResult={getScanResult} />
                  </div>
                }
              </div>
              <div className="form__item third" ></div>

            </div>
          }

        </div>
        :
        <DispalyResult
          isScanResultValid={isScanResultValid}
          processRestart={processRestart}
          industrialCode={industrialCode}
          serialNumber={serialNumber}
        />
      }

      <div className="footer">
        {BrandMapper(props.brand) === 'ka'
          ? <p>{t('footer.text')} <a href={t('ka.footer.link')} target="_blank">{t('footer.link_text')}</a></p>
          : <p>{t('footer.text')} <a href={t('footer.link' + footerLinkUrl)} target="_blank">{t('footer.link_text')}</a></p>
        }
      </div>
    </div>
  )
}

function footerLink () {
  const country = LanguageRetriever(['LANG', 'COUNTRY']);

  return '_'+country.toLowerCase();
}