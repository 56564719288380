import QueryString from './QueryString';

const SerialNumberExtractor = function (scannedCode) {

  if (scannedCode.startsWith('https')) {
    const queryString = QueryString(scannedCode)
    return queryString.sn || "";
  }

  switch (scannedCode.length) {
    case 24:
      return scannedCode.substr(12, 12)
    case 20:
      return scannedCode.startsWith('999') ? scannedCode.substr(10, 11) : scannedCode.substr(11, 9)
    default:
      return ""
  }
}

export default SerialNumberExtractor
