/**
 * This method is used to map the brand passed as query param to the brand value used into code application
 * @param {string} brand The brand passed as query string in the URL
 * @returns The brand value correctly mapped
 */
const BrandMapper = function(brand) {
    switch (brand) {
      case '012':
        return 'ka'
      default:
        return brand
    }
  }
  
  export default BrandMapper
  