import React, { useState, useEffect } from 'react';
import {useTranslation} from "react-i18next";
import CategoryList from './CategoryList'

export default function PopUp(props) {
  const { t, i18n } = useTranslation('common');
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        closeModal()
      }
    }

    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    }
  })

  function openModal() {
    document.querySelector('body').style.height = 'auto';
    document.querySelector('body').style.overflow = 'hidden';
    setShowModal(true)
  }

  function closeModal() {
    document.querySelector('body').style.height = 'auto';
    document.querySelector('body').style.overflow = 'initial';
    setShowModal(false)
  }
  
  if (showModal) {
    return (
      <div>
        <div className="form__item popup-cta" onClick={openModal}>
            {t('main.popup_cta')}
        </div>
        <div className="popup">
          <div className="popup__content">
            <span className="popup__close" onClick={closeModal}>X</span>
            <h2 className="popup__title"></h2>
            <p className="popup__text-title">{t('main.popup_text')}</p>
            <CategoryList />
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="form__item popup-cta" onClick={openModal}>
          {t('main.popup_cta')}
      </div>
    )
  }
}