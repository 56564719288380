import React from 'react';
import {useTranslation} from "react-i18next";


export default function TokenValidationError(props) {
  const { t, i18n } = useTranslation('common');

  return (
    <div className="form__items">
      <div className="form__item first" >
        {props.isResponseLoaded === 0
          ?
            <div className="form__item loader"></div>
          :
            <span dangerouslySetInnerHTML={
                {__html: t('main.error', {interpolation: {escapeValue: false}})}
            } />
        }
      </div>
    </div>
  );
}
